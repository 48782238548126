'use strict';

/*
TEST:
- PPE
- Survey
- BP Reg [configured]
- FC Reg

TO DO:
- Hide input button
*/

(function($) {

	var fields, formElement, ownerInfo, card, elements, stripe;
	var status = 0;
	var $submitEvent;

	var stripeStyle = {
		base: {
			color: '#32325d',
			fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
			fontSmoothing: 'antialiased',
			fontSize: '16px',
			'::placeholder': {
				color: '#aab7c4'
			}
		},
		invalid: {
			color: '#fa755a',
			iconColor: '#fa755a'
		}
	};
    
	
	$(document).on('gform_post_render', function(event, form_id, current_page) {
		if ( 48 === form_id ) {
			//payment-method stripe
			if (jQuery("#field_48_2").is(":visible") && $('#payment-form').is(":visible") ) {
				$("#card-errors").html(stripe_data.strings.required);
				$("#gform_submit_button_48").attr("disabled", "disabled");  
				// if (jQuery("#field_48_2").is(":visible")) {
				//   jQuery("#gform_submit_button_48").hide();
				//   jQuery("#gform_submit_button_48").attr("disabled", "disabled");
				// }
				var attrAction = $('#payment-form').attr("action");
				var attrMethod = $('#payment-form').attr("method");
				var atext = $('#payment-form').html();
				var attrId = $('#payment-form').attr("id");
				var lang = $('#payment-form').attr("data-lang");
				$('#payment-form').replaceWith('<form action="' + attrAction + '" method="' + attrMethod + '" id="' + attrId + '">' + atext + '</form>');
				var prov = jQuery("#payment-method-state").val();
				var key = getStripeKey( getWareHouseRegion( prov ) );
				var stripe = Stripe( key );
				// console.log('prov: ' + prov);
				// console.log('key: ' + key);
				// var stripe = Stripe(getStripeKey(2));
				var elements = lang ? stripe.elements({
					locale: lang
				}) : stripe.elements();
				var style = {
				base: {
					color: '#32325d',
					fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
					fontSmoothing: 'antialiased',
					fontSize: '16px',
					'::placeholder': {
						color: '#aab7c4'
					}
				},
				invalid: {
					color: '#fa755a',
					iconColor: '#fa755a'
				}
				};
				var card = elements.create('card', {
					style: style,
					hidePostalCode: true,
				});
				card.hidePostalCode = true;
				card.mount('#card-element');
				card.addEventListener('change', function(event) {
				var displayError = document.getElementById('card-errors');
				if (event.error) {
					displayError.textContent = event.error.message;
				} else {
					displayError.textContent = '';
				}

				if (event.complete) {
					$("#gform_submit_button_48").removeAttr("disabled");
				} else {
					$("#gform_submit_button_48").attr("disabled", "disabled");  
				}
				});
				var form = document.getElementById('payment-form');
				var ownerInfo = {
					owner: {
						email: $("#payment-method-email").val(),
						name: $("#payment-method-name").val(),
					},
				};
				form.addEventListener('submit', function(event) {
					event.preventDefault();
					stripe.createSource(card, ownerInfo).then(function(result) {
						if (result.error) {
							var errorElement = document.getElementById('card-errors');
							errorElement.textContent = result.error.message;
							$('#gform_ajax_spinner_48').hide();
						} else {
							// console.log(result);
							$("#input_48_3").val(result.source.id);
							$("#input_48_4").val(result.source.card.last4);
							$("#input_48_5").val(result.source.card.exp_year);
							$("#input_48_6").val(result.source.card.exp_month);
							$("#input_48_7").val(result.source.card.brand);
							$("#input_48_8").val($("#payment-method-gcs").val());
							if (location.hostname.match('lessalonsgreencircle')) {
								jQuery("#card-errors").html("Carte ajoutée avec succès. Patientez s'il-vous-plait...");
							} else {
								jQuery("#card-errors").html("Card added successfully. Please wait a moment...");
							}
							
							if ( 0 === status) {
								status = 1;
								$('#gform_48').trigger('submit');
							}
						}
					});	
				});

				$('#gform_48').on('submit', function(e) {
					if (status != 0) {
						status = 0;
						return true;
					}

					e.preventDefault();
					e.stopPropagation();

					$('form#payment-form button').trigger('click');
				});
			}
			//end payment-method stripe
		} else {
			gFormStripe();
			paymentListener();
		}
	});

	function paymentListener() {
		$('#payment-form').on('submit', function(e) {
			e.preventDefault();
			processStripe();
		});

		$('.gform_wrapper form').on('submit', function(e) {
			$stripeForm = $('#payment-form');
			console.log('payment-form', $(this).attr("id") );

			if (status != 0) {
				e.preventDefault();
				e.stopPropagation();
				return;
			}
	
			if ( $(this).attr("id") != "payment-form" && $stripeForm.is(":visible") && ! $("#payment-form").hasClass('complete') ) {
				var id = $(this).attr("id").split("_")[1];
				var targetPage = $('#gform_target_page_number_'+id).val(), currentPage = $('#gform_source_page_number_'+id).val();
				// console.log(targetPage, currentPage, parseInt(targetPage), parseInt(currentPage));
				// Apply to next button only
				if ( parseInt(targetPage) > parseInt(currentPage) ) {
					e.preventDefault();
					e.stopPropagation();
					// console.log('NEVER CONTINUE!2');
					
					status = 1;
					$submitEvent = $(this);
					$('form#payment-form button').trigger('click');
					//$('#payment-form').trigger('submit');
				}
				
			}
		});
	}
	
	function gFormStripe() {
		$stripeForm = $('#payment-form');

		if ($stripeForm.is(":visible")) {
			var mode_of_payment = $(".mode_of_payment input").val();
			if (mode_of_payment != "EFT" && mode_of_payment != "Cheque" && mode_of_payment != "Pays Online") {
				$("#card-errors").html(stripe_data.strings.required);
				// Disable next button on form
				// if ($("#field_40_35").is(":visible")) {
				//     $("#gform_submit_button_40").attr("disabled", "disabled");
				// }

				fields = {
					warehouse: $('#payment-form').attr("data-warehouse"),
					email: $('#payment-form').attr("data-email"),

					stripeSource: $('#payment-form').attr("data-stripe-source"),
					stripeLast4: $('#payment-form').attr("data-stripe-last4"),
					stripeExpYear: $('#payment-form').attr("data-stripe-expiry-year"),
					stripeExpMonth: $('#payment-form').attr("data-stripe-expiry-month"),
					stripeBrand: $('#payment-form').attr("data-stripe-brand"),
				}

				console.log(fields);

				formElement = {
					action: $('#payment-form').attr("action"),
					method: $('#payment-form').attr("method"),
					content: $('#payment-form').html(),
					id: $('#payment-form').attr("id"),
					lang: $('#payment-form').attr("data-lang")
				}

				$('#payment-form').replaceWith('<form action="' + formElement.action + '" method="' + formElement.method + '" id="' + formElement.id + '">' + formElement.content + '</form>');
				var warehouse = $(fields.warehouse).val();
				var key = getStripeKey(getWareHouseRegion(warehouse));
				stripe = Stripe(getStripeKey(getWareHouseRegion(warehouse)));

				elements = formElement.lang ? stripe.elements({ locale: formElement.lang }) : stripe.elements();
				
				card = elements.create('card', { style: stripeStyle });
				
				card.hidePostalCode = true;
				card.mount('#card-element');
				card.addEventListener('change', function(event) {
				var displayError = document.getElementById('card-errors');
					if (event.error) {
						displayError.textContent = event.error.message;
					} else {
						displayError.textContent = '';
					}
				});
						
				ownerInfo = {
					owner: {
						email: $(fields.email).val()
					},
				};

				$(document).off("change", fields.email, changeEmailField);
				$(document).on("change", fields.email, changeEmailField);

			} else {
				$stripeForm.hide();
			}
		}
	}

	function changeEmailField() {
		ownerInfo = {
			owner: {
			  email: $(fields.email).val()
			},
		};
		console.log("email changed");
	}

	function processStripe() {
		stripe.createSource(card, ownerInfo).then(function(result) {
			status = 0;

			if (result.error) {
				var errorElement = document.getElementById('card-errors');
				errorElement.textContent = result.error.message;
				$(".gform_ajax_spinner").remove();
			} else {
				$(fields.stripeSource).val(result.source.id);
				$(fields.stripeLast4).val(result.source.card.last4);
				$(fields.stripeExpYear).val(result.source.card.exp_year);
				$(fields.stripeExpMonth).val(result.source.card.exp_month);
				$(fields.stripeBrand).val(result.source.card.brand);

				// console.log("source", result.source);
				$("#payment-form").addClass('complete');
				if ($submitEvent) {
					$submitEvent.trigger('submit');
				}
				
				
				//$stripeForm.attr("data-continue", "yes");

				// if (location.hostname.match('lessalonsgreencircle')) {
				// 	jQuery("#card-errors").html("Carte ajoutée avec succès");
				// } else {
				// 	jQuery("#card-errors").html("Card saved successfully");
				// }
				// jQuery("#gform_submit_button_40").removeAttr("disabled");
			}
		});
	}


	function getWareHouseRegion(province) {
		var NW = ["AK", "AZ", "CA", "HI", "ID", "MT", "NV", "OR", "UT", "WA", "WY"];
		var IL = ["AL", "AR", "CO", "CT", "DE", "FL", "GA", "IA", "IL", "IN", "KS", "KY", "LA", "MA", "MD", "ME", "MI", "MN", "MO", "MS", "NC", "ND", "NE", "NH", "NJ", "NM", "NY", "OH", "OK", "PA", "RI", "SC", "SD", "TN", "TX", "VA", "VT", "WI", "WV", "DC"];
		var AB = ["AB", "NT", "SK"];
		var BC = ["BC", "YK"];
		var ON = ["MB", "ON"];
		var QC = ["NB", "NF", "NS", "PE", "QC", "NL"];
		// 20220923 - introduction of GCI
		var GCICAN = ["GCICAN"];
		var GCIUSA = ["GCIUSA"];
		var warehouses = [NW, IL, AB, BC, ON, QC, GCICAN, GCIUSA];
		var ware = "";
		for (var i = 0; i < warehouses.length; i++) {
			if (warehouses[i].indexOf(province) !== -1) {
				ware = i;
			}
		};
		return ware;
	}

	function getStripeKey(warehouse) {
		var key = "";
		// Order of warehouse keys = [NW,IL,AB,BC,ON,QC]
		// 20220923 - introduction of GCI
		// Order of warehouse keys = [NW,IL,AB,BC,ON,QC,GCICAN,GCIUSA]
		if (warehouse == 0) {
			key = stripe_data.keys["NW"];
		} else if (warehouse == 1) {
			key = stripe_data.keys["IL"];
		} else if (warehouse == 2) {
			key = stripe_data.keys["AB"];
		} else if (warehouse == 3) {
			key = stripe_data.keys["BC"];
		} else if (warehouse == 4) {
			key = stripe_data.keys["ON"];
		} else if (warehouse == 5) {
			key = stripe_data.keys["QC"];
		} else if (warehouse == 6) {
			key = stripe_data.keys["GCICAN"];
		} else if (warehouse == 7) {
			key = stripe_data.keys["GCIUSA"];
		}
		return key;
	}
    

})(jQuery);