if('undefined' !== typeof gform) {
  gform.addFilter( 'gform_datepicker_options_pre_init', function( optionsObj, formId, fieldId ) {
      if (formId == 39 || formId == 53) {
          optionsObj.beforeShowDay = disableDates;
          optionsObj.minDate = 1;
          optionsObj.maxDate = '2 W';
      }
      // optionsObj.firstDay = 1;

      function disableDates(date) {
          var day = date.getDay();
          var holidays = [
                'Nov 11, 2019 00:00:00',
                'Nov 28, 2019 00:00:00',
                'Dec 25, 2019 00:00:00',
                'Dec 26, 2019 00:00:00',
                // 2020
                'Jan 1, 2020 00:00:00',
                'Jan 20, 2020 00:00:00',
                'Feb 17, 2020 00:00:00',
                'Feb 21, 2020 00:00:00',
                'Apr 10, 2020 00:00:00',
                'Apr 13, 2020 00:00:00',
                'May 18, 2020 00:00:00',
                'May 25, 2020 00:00:00',
                'Jul 1, 2020 00:00:00',
                'Jul 3, 2020 00:00:00',
                'Jul 4, 2020 00:00:00',
                'Aug 3, 2020 00:00:00',
                'Sep 7, 2020 00:00:00',
                'Oct 12, 2020 00:00:00',
                'Nov 11, 2020 00:00:00',
                'Nov 26, 2020 00:00:00',
                'Dec 25, 2020 00:00:00',
                'Dec 26, 2020 00:00:00',
                
                // 2021
                'Jan 1, 2021 00:00:00',
                'Jan 18, 2021 00:00:00',
                'Feb 15, 2021 00:00:00',
                'Feb 26, 2021 00:00:00',
                'Apr 2, 2021 00:00:00',
                'Apr 5, 2021 00:00:00',
                'May 24, 2021 00:00:00',
                'May 31, 2021 00:00:00',
                'Jul 1, 2021 00:00:00',
                'Jul 4, 2021 00:00:00',
                'Jul 5, 2021 00:00:00',
                'Aug 2, 2021 00:00:00',
                'Sep 6, 2021 00:00:00',
                'Sep 30, 2021 00:00:00',
                'Oct 11, 2021 00:00:00',
                'Nov 11, 2021 00:00:00',
                'Nov 25, 2021 00:00:00',
                'Dec 24, 2021 00:00:00',
                'Dec 25, 2021 00:00:00',
                'Dec 26, 2021 00:00:00',
                'Dec 31, 2021 00:00:00',

                // 2022
                'Jan 1, 2022 00:00:00',
                'Jan 3, 2022 00:00:00',
                'Feb 21, 2022 00:00:00',
                'Apr 15, 2022 00:00:00',
                'Apr 17, 2022 00:00:00',
                'May 8, 2022 00:00:00',
                'May 23, 2022 00:00:00',
                'May 30, 2022 00:00:00',
                'Jun 19, 2022 00:00:00',
                'Jun 24, 2022 00:00:00',
                'Jul 1, 2022 00:00:00',
                'Jul 4, 2022 00:00:00',
                'Aug 1, 2022 00:00:00',
                'Sep 5, 2022 00:00:00',
                'Sep 30, 2022 00:00:00',
                'Oct 10, 2022 00:00:00',
                'Nov 11, 2022 00:00:00',
                'Nov 24, 2022 00:00:00',
                'Dec 25, 2022 00:00:00',
                'Dec 26, 2022 00:00:00',
                'Dec 27, 2022 00:00:00',
                
                // 2023
                'Jan 1, 2023 00:00:00',
                'Jan 2, 2023 00:00:00',
                'Apr 7, 2023 00:00:00',
                'Apr 9, 2023 00:00:00',
                'May 14, 2023 00:00:00',
                'May 22, 2023 00:00:00',
                'May 29, 2023 00:00:00',
                'Jun 26, 2023 00:00:00',
                'Jul 3, 2023 00:00:00',
                'Jul 4, 2023 00:00:00',
                'Aug 7, 2023 00:00:00',
                'Sep 4, 2023 00:00:00',
                'Sep 30, 2023 00:00:00',
                'Oct 2, 2023 00:00:00',
                'Oct 9, 2023 00:00:00',
                'Nov 11, 2023 00:00:00',
                'Nov 23, 2023 00:00:00',
                'Dec 24, 2023 00:00:00',
                'Dec 25, 2023 00:00:00',
                'Dec 26, 2023 00:00:00',
                'Dec 31, 2023 00:00:00',

                // 2024
                'Jan 1, 2024 00:00:00',
                'Jan 15, 2024 00:00:00',
                'Feb 19, 2024 00:00:00',
                'Mar 29, 2024 00:00:00',
                'May 20, 2024 00:00:00',
                'May 27, 2024 00:00:00',
                'Jun 24, 2024 00:00:00',
                'Jul 1, 2024 00:00:00',
                'Jul 4, 2024 00:00:00',
                'Aug 5, 2024 00:00:00',
                'Aug 7, 2024 00:00:00',
                'Sep 2, 2024 00:00:00',
                'Sep 30, 2024 00:00:00',
                'Oct 14, 2024 00:00:00',
                'Nov 11, 2024 00:00:00',
                'Nov 28, 2024 00:00:00',
                'Dec 25, 2024 00:00:00',
                'Dec 26, 2024 00:00:00',

                // 2025
                'Jan 1, 2025 00:00:00',
          ];
          var isHoliday = false;

          for (i in holidays) {
              if (new Date(holidays[i]).getTime() == date.getTime()) {
                  isHoliday = true;
              }
          }

        //   console.log("date", date, isHoliday);
          return [(day > 0 && day < 6 && !isHoliday), ""];
      }

      // optionsObj.yearRange = '-0:+1';

      $(".datepicker").attr("readonly", "readonly");

      return optionsObj;
  } );
}

jQuery(document).ready(function ($) {
//    $('#input_39_16').bind('change paste keyup',function(){
//         if(!Number.isInteger($(this).val())){
//             $(this).val($(this).val().slice(0, -1));
//         }
//    });
   // $("#input_39_16,#input_39_17").attr("placeholder", "Enter a number");
   // $("#input_39_16,#input_39_17").bind("keypress", function (e) {
        // var keyCode = e.which ? e.which : e.keyCode
            
        // if (!(keyCode >= 48 && keyCode <= 57)) {
            // $(".error").css("display", "inline");
            // return false;
        // }else{
            // $(".error").css("display", "none");
        // }
    // });
	$('#input_39_16,#input_39_17').inputmask({ 
		mask: "*{*}", 
		greedy: false, 
		definitions: { '#': { validator: "[0-9]", cardinality: 1}}
	});
});

function getTodaysDate(){
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = mm + '/' + dd + '/' + yyyy;
    return today;
    //document.write(today);
}