'use strict';

(function ($) {

  $(document).on('gform_post_render', function (event, form_id, current_page) {
    authnet_gForm();
    // if (48 === form_id) {
    //   //payment-method stripe
    //   // if (jQuery("#field_48_2").is(":visible")) {
    //   //   $("#card-errors").html(stripe_data.strings.required);
    //   //   $("#gform_submit_button_48").attr("disabled", "disabled");
    //   //   // if (jQuery("#field_48_2").is(":visible")) {
    //   //   //   jQuery("#gform_submit_button_48").hide();
    //   //   //   jQuery("#gform_submit_button_48").attr("disabled", "disabled");
    //   //   // }
    //   //   var attrAction = $('#payment-form').attr("action");
    //   //   var attrMethod = $('#payment-form').attr("method");
    //   //   var atext = $('#payment-form').html();
    //   //   var attrId = $('#payment-form').attr("id");
    //   //   var lang = $('#payment-form').attr("data-lang");
    //   //   $('#payment-form').replaceWith('<form action="' + attrAction + '" method="' + attrMethod + '" id="' + attrId + '">' + atext + '</form>');
    //   //   var prov = jQuery("#payment-method-state").val();
    //   //   var key = getStripeKey(getWareHouseRegion(prov));
    //   //   var stripe = Stripe(key);
    //   //   // console.log('prov: ' + prov);
    //   //   // console.log('key: ' + key);
    //   //   // var stripe = Stripe(getStripeKey(2));
    //   //   var elements = lang ? stripe.elements({
    //   //     locale: lang
    //   //   }) : stripe.elements();
    //   //   var style = {
    //   //     base: {
    //   //       color: '#32325d',
    //   //       fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    //   //       fontSmoothing: 'antialiased',
    //   //       fontSize: '16px',
    //   //       '::placeholder': {
    //   //         color: '#aab7c4'
    //   //       }
    //   //     },
    //   //     invalid: {
    //   //       color: '#fa755a',
    //   //       iconColor: '#fa755a'
    //   //     }
    //   //   };
    //   //   var card = elements.create('card', {
    //   //     style: style,
    //   //     hidePostalCode: true,
    //   //   });
    //   //   card.hidePostalCode = true;
    //   //   card.mount('#card-element');
    //   //   card.addEventListener('change', function (event) {
    //   //     var displayError = document.getElementById('card-errors');
    //   //     if (event.error) {
    //   //       displayError.textContent = event.error.message;
    //   //     } else {
    //   //       displayError.textContent = '';
    //   //     }

    //   //     if (event.complete) {
    //   //       $("#gform_submit_button_48").removeAttr("disabled");
    //   //     } else {
    //   //       $("#gform_submit_button_48").attr("disabled", "disabled");
    //   //     }
    //   //   });
    //   //   var form = document.getElementById('payment-form');
    //   //   var ownerInfo = {
    //   //     owner: {
    //   //       email: $("#payment-method-email").val(),
    //   //       name: $("#payment-method-name").val(),
    //   //     },
    //   //   };
    //   //   form.addEventListener('submit', function (event) {
    //   //     event.preventDefault();
    //   //     stripe.createSource(card, ownerInfo).then(function (result) {
    //   //       if (result.error) {
    //   //         var errorElement = document.getElementById('card-errors');
    //   //         errorElement.textContent = result.error.message;
    //   //         $('#gform_ajax_spinner_48').hide();
    //   //       } else {
    //   //         // console.log(result);
    //   //         $("#input_48_3").val(result.source.id);
    //   //         $("#input_48_4").val(result.source.card.last4);
    //   //         $("#input_48_5").val(result.source.card.exp_year);
    //   //         $("#input_48_6").val(result.source.card.exp_month);
    //   //         $("#input_48_7").val(result.source.card.brand);
    //   //         $("#input_48_8").val($("#payment-method-gcs").val());
    //   //         if (location.hostname.match('lessalonsgreencircle')) {
    //   //           jQuery("#card-errors").html("Carte ajoutée avec succès. Patientez s'il-vous-plait...");
    //   //         } else {
    //   //           jQuery("#card-errors").html("Card added successfully. Please wait a moment...");
    //   //         }

    //   //         if (0 === status) {
    //   //           status = 1;
    //   //           $('#gform_48').trigger('submit');
    //   //         }
    //   //       }
    //   //     });
    //   //   });

    //   //   $('#gform_48').on('submit', function (e) {
    //   //     if (status != 0) {
    //   //       status = 0;
    //   //       return true;
    //   //     }

    //   //     e.preventDefault();
    //   //     e.stopPropagation();

    //   //     $('form#payment-form button').trigger('click');
    //   //   });
    //   // }
    //   //end payment-method stripe
    // } else {
    //   authnet_gForm();
    //   // authnet_paymentListener();
    // }
  });

  function authnet_gForm() {
		var $container = $( '#authnet-container' );

		if ($container.is(":visible")) {
      var $button = $( '.AcceptUI' );
      var $errorContainer = $( '#authnet-card-errors' );

      var warehouse = $( $button.attr("data-warehouse") ).val();
      console.log( warehouse );

      $button.attr( 'data-apiLoginID', authnet_data.login_ids[ warehouse ] );
      $button.attr( 'data-clientKey', authnet_data.public_client_keys[ warehouse ] );
      $button.attr( 'data-acceptUIFormHeaderTxt', authnet_data.strings.header );
      $button.attr( 'data-acceptUIFormBtnTxt', authnet_data.strings.submit );

      $container.append( authnet_data.script );
    }
  }

  window.authnet_responseHandler = function( response ) {
    console.log( response );
  };

})(jQuery);
